<template>
  <div>
    <b-sidebar
      id="sidebar-file-upload-handler"
      sidebar-class="sidebar-lg"
      :visible="fileUploadSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateFileUploadSidebar({ visible: val })"
      @hidden="clearFile"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>{{ fileUploadSidebar.title }}</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <!-- Title -->
          <b-form-group
            :label="fileUploadSidebar.description"
            label-for="file-input"
          >
            <b-form-file
              :disabled="saving"
              id="file-input"
              autofocus
              :accept="fileUploadSidebar.fileType || 'application/pdf,image/jpeg,image/png'"
              placeholder="Escolher arquivo..."
              v-model="file"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="saving || !file"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ saving ? 'Enviando...' : 'Enviar' }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BButton,
} from 'bootstrap-vue'
import { mapMutations, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      file: undefined,
    }
  },
  computed: {
    ...mapGetters({
      fileUploadSidebar: types.FILE_UPLOAD_SIDEBAR
    })
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  methods: {
    ...mapMutations({
      mutateFileUploadSidebar: types.MUTATE_FILE_UPLOAD_SIDEBAR
    }),
    clearFile() {
      this.file = undefined
    },
    onSubmit() {
      this.saving = true
      const request = new FormData();
      request.append("id", this.fileUploadSidebar.id);
      request.append("file", this.file);
      this.fileUploadSidebar.saveAction(request)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "O arquivo foi enviado com sucesso",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.clearFile()
          this.mutateFileUploadSidebar({ visible: false })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao enviar o arquivo. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
}
</script>
