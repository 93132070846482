<template>
  <div>
    <single-file-upload-handler-sidebar />
    <h1>Arquivos</h1>
    <div class="d-flex align-items-center justify-content-end m-2">
      <b-button variant="primary" @click="addFile">
        <feather-icon icon="PlusCircleIcon" class="mr-50" />
        <span class="text-nowrap">Novo arquivo</span>
      </b-button>
    </div>
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="items.length > 0">
      <b-card no-body class="mb-0 m-2">
        <b-table
          :items="items"
          striped
          borderless
          responsive
          :fields="tableColumns"
          show-empty
          empty-text="Não foram encontrados arquivos"
          :busy="loading"
        >
          <!-- Column: name -->
          <template #cell(name)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.file_name
              }}</span>
            </div>
          </template>
          <!-- Column: created_at -->
          <template #cell(created_at)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.created_at | dateToStr
              }}</span>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(user)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.user.name
              }}</span>
            </div>
          </template>
          <!-- Column: download -->
          <template #cell(download)="data">
            <b-button
              variant="link"
              size="lg"
              no-caret
              @click="download(data.item.id)"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
          </template>
        </b-table>
      </b-card>
    </template>
    <template v-else>
      <span class="d-flex justify-content-center">
        Não foram encontrados arquivos
      </span>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import SingleFileUploadHandlerSidebar from "@/modules/shared/components/SingleFileUploadHandlerSidebar";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    SingleFileUploadHandlerSidebar,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "name", label: "Nome do arquivo" },
        { key: "created_at", label: "Data do upload" },
        { key: "user", label: "Responsável" },
        { key: "download", label: "Download" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      files: types.FILES,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.files || [];
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions({
      getFiles: types.GET_FILES,
      openFileUploadSidebar: sharedTypes.OPEN_FILE_UPLOAD_SIDEBAR,
      attachContractFile: types.SAVE_ATTACHMENT,
      downloadFile: types.DOWNLOAD_FILE,
    }),
    async save(payload) {
      await this.attachContractFile(payload).then((resp) => {
        this.get();
        return resp;
      });
    },
    addFile() {
      this.openFileUploadSidebar({
        title: "Anexo do contrato",
        description: `Escolha um documento para anexar ao contrato Nº ${this.$route.params.id}`,
        id: this.$route.params.id,
        saveAction: this.save,
      });
    },
    get() {
      this.loading = true;
      this.getFiles({
        id: this.$route.params.id,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os arquivos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    download(idFile) {
      this.downloadFile(idFile)
        .then((response) => {
          setTimeout(() => {
            window.open(response.data, "_blank").focus();
          });
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: `Ocorreu um erro ao fazer download. Entre em contato com o setor de TI.`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
